.home-input-block {
    position: relative;
}

.home-input-block input {
    width: 100%;
    color: var(--color-white);
    height: 2.5rem;
    margin-top: 0.8rem;
    border-radius: 1rem 0 0 1rem;
    background: var(--color-primary);
    border: 0;
    outline: 0;
    padding: 0 1.6rem;
}

.home-input-block:focus-within::after {
    width: (100% - 3.2rem);
    height: 2px;
    content: "";
    background: var(--color-primary-lighter);
    position: absolute;
    left: 1.6rem;
    right: 1.6rem;
    bottom: 0;
}

::-webkit-input-placeholder {
   color: var(--color-white);
}

:-moz-placeholder { /* Firefox 18- */
   color: var(--color-white);  
}

::-moz-placeholder {  /* Firefox 19+ */
   color: var(--color-white);  
}

:-ms-input-placeholder {  
   color: var(--color-white);  
}
 