#favorites-main {
    padding: 40px 0;
}

#favorites-main .container {
    flex-direction: column;
    align-items: center;
    min-height: 25vh;
}

#favorites-main .container .card-imovel {
    max-width: 900px;
}

#favorites-main .no-favorites {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

#favorites-main .no-favorites h2 {
    font-size: 1.4rem;
    text-align: center;
}

#favorites-main .no-favorites p {
    text-align: center;
    font-size: 1rem;
    font-weight: 300;
}

#favorites-main .no-favorites p i {
    padding: .8rem;
    border-radius: .5rem .5rem;
    color: var(--color-red);
    border: 1px solid var(--color-primary);
}