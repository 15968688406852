.topo-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    background: var(--color-white);
    padding: 1rem;
    margin-bottom: 1.5rem;

    border-bottom: 2px solid var(--color-primary-light);
}

.topo-content h1 {
    font-size: 1.1rem;
    margin: 0;
}

.topo-content .block-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topo-content .block-buttons button {
    display: none;
    background: var(--color-primary-light);
    color: var(--color-white);
}

.topo-content .block-buttons > button,
.topo-content .block-buttons > form select {
    width: 100%;
    max-width: 146px;
    height: 40px;
    font-weight: 300;
    border: 1px solid var(--color-primary-light) !important;

    border-radius: .2rem;    
}

@media(max-width: 900px) {
    .topo-content .block-buttons button {
        display: block;
    }
}

@media(max-width: 767px) {
    .topo-content .block-buttons > button,
    .topo-content .block-buttons > form select {
        width: 130px;
    }
}