.field-box {
    position: relative;
    display: flex;
    justify-content: center;
}

.field-box i {
    margin-left: 10px;
    color: var(--color-primary-lighter);
}

.field-box span {
    background: var(--color-white);
    padding: 1rem 2rem;
    font-size: 1rem;
    transition: .4s;
    cursor: pointer;    
    border: none;
}

.field-box span:focus {    
    color: var(--color-primary);
    outline: none;
}

.field-box span:focus + .field-list {    
    opacity: 1;
    transform: translateY(0);
    pointer-events: all;
}

.field-list {
    position: absolute;
    background-color: var(--color-primary-light);
    border-radius: 1rem;
    font-size: 1rem;
    line-height: 2;
    min-width: 200px;
    z-index: 10;
    margin-top: 2rem;
    opacity: 0;
    transform: translateY(4rem);
    transition: all 0.6s ease;
    pointer-events: none;
    overflow: hidden;
}

.field-list .field-list-item a {
    color: var(--color-white);    
    padding: .5rem 1rem;
    display: block;
}

.field-list .field-list-item:hover {
    background: var( --color-primary-lighter);
    pointer-events: all;    
}