#post-blog {
    padding: 15px 0 36px;
}

#post-blog .container {
    justify-content: center;
    flex-direction: column;
}


#post-blog .post-wrappers {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#post-blog .post-wrappers article {
   display: flex;
   width: 30%;
}

#post-blog article img {
    max-width: 120px;
    height: 100px;
    object-fit: cover;
    border-radius: 10px;
    margin-right: 15px;
}

#post-blog .post-wrappers article h2 {
    font-size: 1.1rem;
    margin: 0;
}

#post-blog .post-wrappers article p {
    color: var(--color-grey);
    font-weight: 300;
    font-size: .9rem;
    margin:  0 0 5px;
}

#post-blog .post-wrappers article a {
    font-size: .9rem;
    color: var(--color-primary);
}

@media(max-width: 900px) {
    #post-blog .post-wrappers {
        flex-wrap: wrap;
    }

    #post-blog .post-wrappers article {       
        width: 100%;
    }

    #post-blog  article:nth-child(2) {
        margin: 1rem 0;
    }
}