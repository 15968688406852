.modal-header h5 {
    color: var(--color-primary-light);
}

.form-survey input,
.form-survey textarea {
    border: 1px solid var(--color-primary-lighter) !important;
    margin-bottom: 15px;
    box-shadow: none;

    font-size: .85rem;
    font-weight: 300;
}

.form-survey input {
    height: 48px;
}

.form-survey input:focus,
.form-survey textarea:focus {
    box-shadow: none;
    border: 2px solid var(--color-secondary);
}

.form-survey .box-file {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background: var(--color-primary-light);
    border-radius: .3rem;
    border: 1px solid var(--color-primary-lighter);

    height: 48px;
    margin-bottom: 1rem;
}

.form-survey .box-file svg {
    font-size: 1.5rem;
    color: var(--color-primary-lighter);
}

.form-survey .box-file h2 {
    font-size: .85rem;
    font-weight: 300;
    margin: 0;
    color: var(--color-white);
}

.form-survey .box-file h2 span {
    color: var(--color-primary-lighter);
}

.form-survey .box-file input {
    position: absolute;
    top: 0;
    opacity: 0;
}

.form-survey button {
    border: 0;
    background: var(--color-primary-light);
    height: 48px;
    font-size: .85rem;
    font-weight: 300;
    transition: .5s;
}

.form-survey button:hover {
    background: var(--color-primary);
}