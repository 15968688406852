#instruction {
    padding: 15px 0 36px;
}

#instruction .container {
    flex-direction: column;
}

#instruction .container p {
    font-size: .9rem;
    font-weight: 300;
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}

#instruction .container a {
    max-width: 300px;
    margin: 10px auto 0;
    padding: .6rem;

    font-size: .9rem;
    font-weight: 300;

    background: var(--color-primary-light);
    border: none;

    transition: .5s;
}

#instruction .container a:hover {
    opacity: .8;
}