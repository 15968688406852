#search-result-container {
    max-width: 1240px !important;
    height: 100%;
    margin: 0 auto;
    padding: 30px 15px;

    display: flex;
}

#search-result-container .content-search-result {
    width: calc(100% - 25%);
    padding-left: 15px;
    display: flex;
    flex-direction: column;
}

#search-result-container .content-search-result .content-pagination {
    display: table;
    margin:  0 auto;
    padding: 15px 0;
}

#search-result-container .content-search-result 
.content-pagination .pagination {
    margin: 0;
}

#search-result-container .content-search-result 
.content-pagination .pagination li {
    border: 1px solid var(--color-primary-dark);
    padding: 5px 15px;
    cursor: pointer;
}

#search-result-container .content-search-result 
.content-pagination .pagination li:first-child {
    border-radius: .5rem 0 0 .5rem;
}

#search-result-container .content-search-result 
.content-pagination .pagination li:last-child {
    border-radius: 0 .5rem .5rem 0;
}

#search-result-container .content-search-result 
.content-pagination .pagination li.active {
    border: 1px solid var(--color-primary);
    background: var(--color-primary);
}

#search-result-container .content-search-result 
.content-pagination .pagination li a {
    color: var(--color-text-base);
    font-weight: 300;
}

#search-result-container .content-search-result 
.content-pagination .pagination li a:hover {
    color: var(--color-primary-lighter);
}

#search-result-container .content-search-result 
.content-pagination .pagination li.active a {
    color: var(--color-white);
}

#search-result-container .content-search-result 
.content-pagination .pagination li.active a:hover {
    color: var(--color-white);
}