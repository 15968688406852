#details-content {
    background: var(--color-white);
    padding: 1rem 1rem 0;
}

#details-content h1 {
    font-size: 1.2rem;
    color: var(--color-primary-light);
}

#details-content p {
    font-size: .85rem;
    font-weight: 300;
    margin: 0;
    padding-bottom: 1rem;
}

/* CARACTERISTICAS DE DESTAQUES */
#details-content ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    font-size: .9rem;
    padding: 1rem;
    line-height: 1.8;

    border-top: 1px solid var(--color-grey-light);
    border-bottom: 1px solid var(--color-grey-light);
}

#details-content ul li {
    display: flex;
    flex-direction: column;
}

#details-content ul li i {
    font-size: .8rem;
    color: var(--color-primary-lighter);
}

#details-content ul li span:last-child {
    font-weight: 300;
}

/* LISTA DE CARACTERISTICAS */
#details-content .block-list-features {
    padding: 1rem 0 0;
    position: relative;
    border-bottom: 1px solid var(--color-grey-light);
}

#details-content .block-list-features:last-child {
    border-bottom: 0;
}

#details-content .block-list-features h2 { 
    font-size: .9rem;
}

#details-content .block-list-features ul {
    display: block;
    column-count: 3;
    line-height: 25px;
    border: 0;
    padding: 0 0 1rem;    
}

#details-content .block-list-features ul li {
    display: flex;
    flex-direction: row;
    align-items: center;

    font-size: .85rem;
    font-weight: 300;
}

#details-content .block-list-features ul li i {
    color: var(--color-primary-lighter);
}

@media (max-width:550px) {
    #details-content ul li{
        width: 50%;
    }

    #details-content ul li {
        display: block;
    }

    #details-content > ul li i {
        display: none;
    }
}