#header-home {
    position: absolute;
    z-index: 2000;
    width: 100%;
    background: transparent;
    padding: 10px 0;
}

#header-home .container {
    position: relative;
    justify-content: space-between;
    align-items: flex-start;
}

#header-home img {
    max-width: 240px;
    height: auto;
    /* background: var(--color-white);

    border-radius: 10px;
    border: 1px solid var(--color-primary-light); */
}

/* CONTACTS */
#header-home .block-contact,
#header-home .block-contact ul,
#header-home .block-contact ul li,
#header-home .block-contact ul li a,
#header-home .block-contact ul li strong {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--color-white);
}

#header-home .block-contact {
    border-bottom: 1px solid var(--color-background);
    padding-bottom: 10px;
}

#header-home .block-contact ul li {
    flex-direction: row;
    margin-right: 5px;
}

#header-home .block-contact ul li:last-child {
    margin-right: 0;
}

#header-home .block-contact ul li span {
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin-left: 5px;
}
#header-home .block-contact ul li strong {
    font-size: .9rem;
    font-weight: 400;
}

#header-home .block-contact ul li strong svg {
    margin: 0 4px;
}

/* NAVIGATION */
#header-home nav ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

#header-home nav ul li a {
    font-size: .9rem;
    margin-right: 15px;
    color: var(--color-white);
    transition: .4s;
}

#header-home nav ul li a:hover {
    color: var(--color-primary-lighter);
}

#header-home nav ul li:last-child {
    margin-right: 0;
    line-height: 0;
    border-left: 1px solid var(--color-primary);
    padding-left: 10px;
    cursor: pointer;
}

#header-home nav ul li i {
    font-size: 1rem;
    margin: 0 5px;
    color: var(--color-primary);
}

#header-home nav ul li a span {
    color: var(--color-primary);
    background: var(--color-yellow);
    border-radius: 2px;
    min-width: 13px;
    height: 13px;
    font-size: 11px;
    text-align: center;
    margin-top: 7px;
    margin-right: 5px;
    margin-left: -10px;
    line-height: 1.4;
}

@media(min-width: 970px) {
    #header-home .container {
        justify-content: flex-end;
    }

    #header-home img {
        left: 15px;
        position: absolute;
    }
}

@media(max-width: 970px) {
    #header-home .container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #header-home .block-contact {
        display: none;
    }
}

/* @media(max-width: 600px) {
    #header-home .container {
        justify-content: center;
    }
} */

@media(max-width: 400px) {
    #header-home nav ul li:nth-child(1),
    #header-home nav ul li:nth-child(2) {
        display: none;
    }
}
