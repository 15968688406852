#property-contact {
    background: var(--color-white);
    width: 100%;
    position: sticky;
    top: 15px;

    padding: 1rem;
    border-radius: .4rem;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

#property-contact .fa-heart {
    position: absolute;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
    transition: .5s;
    color: var(--color-red);
}

#property-contact .fa-heart:hover {    
    font-size: 25px;
}

#property-contact .fas.fa-heart {    
    color: var(--color-red);
}

#property-contact h2 {
    display: flex;
    font-weight: 300;
    flex-direction: column;
    font-size: .8rem;
    margin-bottom: 20px;
}

#property-contact h2 span {
    font-size: 1.7rem;
    font-weight: 400;
    color: var(--color-primary);
}

#property-contact ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 300;
    font-size: .9rem;
    border-bottom: 1px solid var(--color-grey-light);
    line-height: 2.2;
}

#property-contact .block-alert {
    padding: 1rem .7rem;
    margin-top: 1rem;

    border: 1px solid var(--color-orange);
    border-radius: .5rem;
}

#property-contact .block-alert .alert,
#property-contact .block-alert .buttons {
    display: flex;
    justify-content: space-between;
    align-items: center; 
    margin: 0;
    padding: 0;
}

#property-contact .block-alert .alert svg {
    font-size: 5rem;
    margin-right: 1rem;
    color: var(--color-primary-light);
}

#property-contact .block-alert .alert p {
    font-size: .8rem;
    font-weight: 300;
    margin: 0;
}

#property-contact .block-alert button {
    padding: 0;
    font-size: .9rem;
    margin: 0 auto;
    width: inherit;
    background: transparent;
    color: var(--color-primary-dark);
}

#property-contact .block-alert button:hover {
    color: var(--color-primary-light);
}

#property-contact button,
#property-contact a {
    display: block;
    background: var(--color-primary-light);
    color: var(--color-white);
    font-weight: 300;
    text-align: center;

    width: 100%;
    padding: .8rem;
    margin-top: 1rem;

    border: none;
    border-radius: .5rem;

    transition: .5s;
}

#property-contact a {
    background: var(--color-green);    
}

#property-contact button:hover,
#property-contact a:hover {
    opacity: .8;
}

#property-contact .box-share {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    text-align: center;
}

#property-contact .box-share img {
    width: 50px;
    cursor: pointer;
    margin-bottom: .8rem;
}

#property-contact .react-share__ShareButton {
    margin: 0;
}

#property-contact .box-share .content-box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 1.2rem 1rem 0;
}