#property-file {
    padding: 50px 40px;
}

#property-file address {
    font-size: 16px;
}

#property-file .block-image {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#property-file .block-image img {
    width: 33%;
}

#property-file h2 {
    font-size: 18px;
    font-weight: bold;
}

#property-file p {
    font-size: 16px;
}

#property-file .features span {
    font-size: 16px;
    font-weight: bold;   
}

#property-file .block-list-features ul {
    columns: 3;
}

#property-file .block-list-features h2 {
    font-size: 16px;
}

#property-file .block-list-features ul li {
    padding: 5px 0;
}

#property-file .block-details h3 {
    font-size: 16px;
}

#property-file .block-details h3 span {
    font-weight: bold;
}

