footer {
    background: var(--color-primary-dark);
    color: var(--color-grey-light);
    padding: 36px;    
}

footer .container {
    justify-content: space-between;    
}

footer .footer-box a:hover {
    color: var(--color-primary-lighter);
}

footer .footer-box h2 {
    font-size: 1rem;
    color: var(--color-primary-lighter);
}

footer .footer-box h2 svg, 
footer .footer-box li svg {
    margin-right: .4rem;
}

footer .footer-box ul li,
footer .footer-box ul li a {
    font-size: .9rem;
    font-weight: 300;
    line-height: 1.9;
    transition: .5s;

    display: flex;
    align-items: center;
}

footer .footer-box ul li a svg {
    font-size: 1.5rem;
}

footer .footer-box ul li a:hover {
    color: var(--color-primary-lighter);
}

footer .footer-box.contact {
    border-left: 1px solid var(--color-primary);
    padding-left: 1rem;
}

footer .footer-box.contact h2 {
    margin: 0;
}

footer .footer-box.contact li a svg {
    font-size: 1.7rem;
}

footer .footer-box.contact li .fa-circle {
    margin: 0 .4rem;
}

footer .footer-box.contact li a strong {
    font-size: 1.5rem;
}

footer .box-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 36px 0 0;
}

footer .image-CEF {
    border: 1px solid #fff;
    border-radius: .4rem;
    width: 100%;
    max-width: 270px;
    margin: 0 auto;
    margin-top: 55px;
}

footer .medias {
    display: block;
    margin-bottom: 1rem;
}

footer .medias a {
    width: 30px;
    height: 30px;
    padding: .5rem;
    margin-right: .5rem;
    background: var(--color-primary);
    border-radius: .2rem;
    display: inline-table;
    transition: .5s;
}

footer .medias a:hover {
    color: var(--color-white);
    background: var(--color-primary-lighter);
}

footer .copy-right {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    font-size: .8rem;
    width: 100%;
}

footer .copy-right svg{
    margin-right: .5rem;
}

footer .copy-right a {
    color: var(--color-primary-lighter);
}

@media(max-width: 1020px) {
    footer .container {
        flex-direction: column;
    }

    footer .container .footer-box {
        margin-bottom: 15px;
    }

    footer .container .footer-box.contact {
        border-left: 0;
        padding-left: 0;
    }
}