#gallery-content {
    position: relative;
}

#gallery-content button {
    position: absolute;
    bottom: 15px;
    left: 15px;
    z-index: 2;

    border: none;
    border-radius: .5rem;
    background: rgb(25 91 142 / 80%);
    color: var(--color-white);
    padding: .5rem;

    line-height: 1.2px;

    display: flex;
    justify-content: center;
    align-items: center;

}

#gallery-content .gallery {
    background: var(--color-white);
    padding: 5px;
    display: block;
}

#gallery-content .gallery img {
    width: 100%;
    height: 240px;
    object-fit: cover;
}

/* #gallery-content .gallery img:last-child {
    margin: 0 !important;
} */
