#survey-block-form {
    padding: 0 15px 36px;
    display: flex;
    justify-content: center;
}

#survey-block-form form {
    background: var(--color-primary-light);
    border-radius: .5rem;
    padding: 30px 20px;
    width: 100%;
    max-width: 600px;
    margin-top: -60px;
    box-shadow: 0 0 7px rgb(0 0 0 / 25%);
    z-index: 2;
}

#survey-block-form form input, 
#survey-block-form form select,
#survey-block-form form textarea {
    height: 48px;
    font-size: .9rem;
    font-weight: 300;
    border-radius: .2rem;
    box-shadow: none;
    margin-bottom: 1rem;
}

#survey-block-form form textarea {
    min-height: 100px;
}

#survey-block-form form button {
    border: 0;
    background: var(--color-primary);
    height: 50px;
    font-weight: 300;
    transition: .5s;
}

#survey-block-form form button:hover {
    background: var(--color-primary-dark);
}

#survey-block-form form .block-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#survey-block-form form .block-group .form-control:first-child {
    margin-right: 15px;
}

@media (max-width: 1200px ) {
    #topo-property-registration .content,
    #topo-property-registration .content-form {
        max-width: 100%;        
    }
    #topo-property-registration .content-form {
        padding: 0 0 50px;
    }
}

@media (max-width: 500px ) {
    #survey-block-form form .block-group {
        flex-wrap: wrap;
    }

    #survey-block-form form .block-group .form-control:first-child {
        margin-right: 0;
    }
}

@media (max-width: 350px) {
    #survey-block-form form button {
        width: 100%;
    }
}