#property-details {
    max-width: 1240px !important;
    height: 100%;
    margin: 0 auto;
    padding: 30px 15px;

    display: flex;
}

#property-details .content {
    width: calc(100% - 30%);    
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

#property-details .contact {
    width: 30%;
    padding-left: 15px;
}

#card-code-error {
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.1);
    background-color: var(--color-white);
    margin: 100px 0;
    padding: 40px 30px;
    border-radius: 10px;
}

#card-code-error h1 .fa{
    font-size: 30px;
    margin-right: 15px;
}

#card-code-error h1 {
    font-size: 26px;
    color: var(--color-primary);
}


#card-code-error p {
    font-weight: 300;
}

#card-code-error p strong {
    color: var(--color-red);
    font-weight: 400;
}

#card-code-error a {
    color: var(--color-primary-light);
}

@media(max-width: 990px) {
    #property-details {
        flex-direction: column;
    }

    #property-details .content,
    #property-details .contact {
        width: 100%;
        padding-left: 0;
    }

    #property-details .contact {
        margin-top: 1.5rem;
    }

    #details-content .block-list-features ul {
        columns: 2;
    }

    #details-content .block-list-features ul li {
        width: initial;
    }
}