.modal-header h5 {
    color: var(--color-primary-light);
}

.form-contact input,
.form-contact textarea {
    border: 1px solid var(--color-primary-lighter) !important;
    margin-bottom: 15px;
    box-shadow: none;

    font-size: .85rem;
    font-weight: 300;
}

.form-contact input {
    height: 48px;
}

.form-contact input:focus,
.form-contact textarea:focus {
    box-shadow: none;
    border: 2px solid var(--color-secondary);
}

.form-contact .box-file {
    position: relative;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    background: var(--color-primary-light);
    border-radius: .3rem;
    border: 1px solid var(--color-primary-lighter);

    height: 48px;
    margin-bottom: 1rem;
}

.form-contact .box-file svg {
    font-size: 1.5rem;
    color: var(--color-primary-lighter);
}

.form-contact .box-file h2 {
    font-size: .85rem;
    font-weight: 300;
    margin: 0;
    color: var(--color-white);
}

.form-contact .box-file h2 span {
    color: var(--color-primary-lighter);
}

.form-contact .box-file input {
    position: absolute;
    top: 0;
    opacity: 0;
}

.form-contact button {
    border: 0;
    background: var(--color-primary-light);
    height: 48px;
    font-size: .85rem;
    font-weight: 300;
    transition: .5s;
}

.form-contact button:hover {
    background: var(--color-primary);
}