#featured-services {
    padding: 30px 0;
    background: var(--color-grey-light);    
}

#featured-services .container {
    justify-content: center;
    align-items: center;
}

#featured-services article {
    box-shadow: 0 2px 8px rgb(88 58 188 / 8%);
    background: var(--color-white);
    border-radius: 10px; 
}

#featured-services article:nth-child(2) {
    margin: 0 15px;
}


#featured-services article a {
    max-width: 350px;
    padding: 2.5rem 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;    
}

#featured-services article a:hover svg,
#featured-services article a:hover h2 {
    color: var(--color-primary-lighter) !important
}

#featured-services article a h2 {
    font-size: 1.2rem;
    color: var(--color-primary);
    margin: 0 0 10px;
    transition: .5s;  
}

#featured-services article a p {
    color: var(--color-grey);
    font-weight: 300;
    font-size: .9rem;
    margin: 0;
}

#featured-services article svg {
    margin-right: 15px;
    transition: .5s;  
}

@media(max-width:767px) {
    #featured-services {
        display: none;
    }
}

