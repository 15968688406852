.header-line {
    position: relative;
    text-align: center;
    padding: 1rem 0;
    font-size: 1.8rem;
    margin-bottom: 30px;
}

.header-line::after{
    position: absolute;
    content: '';
    width: 100px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 4px double var(--color-primary-lighter);
}