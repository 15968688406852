#benefits-property-registration  {
    background: var(--color-white);
    padding: 15px 0 36px;
}

#benefits-property-registration .container {
    flex-direction: column;
}

#benefits-property-registration .benefits-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}   

#benefits-property-registration article {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 30%;
    padding: 2rem;
    border: 1px solid var(--color-background);
}

#benefits-property-registration article svg {
    font-size: 1.5rem;
    color: var(--color-primary-lighter);
    margin-bottom: 1rem;
}

#benefits-property-registration article h2 {
    font-size: 1rem;
}

#benefits-property-registration article p {
    font-size: .9rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.6;
    color: var(--color-grey);
    margin: 0;
}

@media(max-width: 600px) {
    #benefits-property-registration article {
        width: 50%;
    }
}

@media(max-width: 440px) {
    #benefits-property-registration article {
        width: 100%;
    }
}