#survey-topo {
    background-image: url("../../../assets/images/background/survey.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 300px;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 0 1rem;
}

#survey-topo::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.35);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.35)), color-stop(100%, transparent));
    background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, transparent 100%);
}

#survey-topo::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: var(--color-primary-dark);
    opacity: .5;
}

#survey-topo h1 {
    text-align: center;
    color: var(--color-white);
    font-size: 2.5rem;
    font-weight: 300;
    z-index: 1;
}

#survey-topo h1 strong {
    color: var(--color-yellow);    
}

#survey-topo p {
    text-align: center;
    font-size: .85rem;
    font-weight: 300;
    color: var(--color-white);
    max-width: 600px;
    z-index: 1;
}
