#popular-properties {
    padding: 15px 0 36px;
    background: var(--color-background);
}

#popular-properties .container {
    justify-content: center;
    max-width: 1170px !important;
}

#popular-properties ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    line-height: 2;
}

#popular-properties ul li {
    width: 29%;
}

#popular-properties ul li a {
    font-size: .9rem;
    font-weight: 300;
    transition: .5s;
    padding: 5px 0;
}

#popular-properties ul li a:hover,
#popular-properties ul li a:hover svg {
    color: var(--color-primary);
}

#popular-properties ul li a svg {
    margin-right: 10px;
}

@media(max-width: 900px) {
    #popular-properties ul li {
        width: 50%;
    }
}

@media(max-width: 600px) {
    #popular-properties ul li {
        width: 100%;
    }
}