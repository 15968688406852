.card-imovel {
    background: var(--color-white);
    padding: .5rem;
    display: flex;
    float: left;
    margin-bottom: 1.5rem;
    transition: .5s;
    width: 100%;
}

.card-imovel:hover {
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

.card-imovel .card-image {
    width: 100%;
    max-width: 336px;
    position: relative;
    height: 250px;
    background: #dedede;
    margin: 0 auto;
}

.card-imovel .card-image img {
    margin: 0 auto;
    width: initial;
    display: block;
    max-height: 250px;
}

.card-imovel .card-image .carousel-nav {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    pointer-events: none;
}

.card-imovel .card-image .carousel-nav i {
    font-size: 2.3rem;
    cursor: pointer;
    color: var(--color-white);
    transition: .5s;
    pointer-events: all;
}

.card-imovel .card-image .carousel-nav i:hover {
    color: var(--color-yellow);
}

.card-imovel .card-wrapper {    
    padding:  0 .8rem;
    width: 100%;
}

.card-imovel .card-wrapper h2 {    
    font-size: 1.3rem;
    color: var(--color-primary-light);
    margin: 0;
}

.card-imovel .card-wrapper h2 a:hover,
.card-imovel .card-wrapper address a:hover {
    color: var(--color-primary);
}

.card-imovel .card-wrapper address {
    font-size: .8rem;
    font-weight: 300;
    margin: 0;
}

.card-imovel p {
    font-size: .8rem;
    font-weight: 300;    

    padding: .5rem 0;
    margin: 0;
    border-bottom: 1px solid var(--color-background);
}

.card-imovel .card-account,
.card-imovel .card-feature {
    display: flex;
}

.card-imovel .card-account {  
    justify-content: space-evenly;
    align-items: center;
    padding: .7rem 0;  
}

.card-imovel .card-account li {
    font-size: .7rem;
    display: flex;
    flex-direction: column;
}

.card-imovel .card-account li span {
    font-size: 1.3rem;
    color: var(--color-primary-light);
}

.card-imovel .card-feature {
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 0 0 .5rem 0;
}

.card-imovel .card-feature li {
    font-size: .85rem;
    font-weight: 300;
}

.card-imovel .card-buttons {
    display: inline-flex;
}

.card-imovel .card-buttons a,
.card-imovel .card-buttons button {
    font-size: .9rem;
    color: var(--color-primary-light);
    padding: 10px;
    background: var(--color-white);

    border: 1px solid var(--color-primary-light);
    border-radius: 5px;
    transition: .5s;
}

.card-imovel .card-buttons button {
    padding: 10px 15px;
    margin-right: 1rem;
    line-height: 1;
    color: var(--color-red);
}


.card-imovel .card-buttons a:hover,
.card-imovel .card-buttons button:hover {
    border: 1px solid var(--color-primary-light);
    background: var(--color-primary-light);
    color: var(--color-white);
}

/* CAROUSEL */
/* .owl-nav {
    display: flex;
    justify-content: space-between;
    margin-top: 0 !important;
}

.owl-nav .owl-prev, .owl-nav .owl-next {
    position: absolute;
    top: 30%;
    text-align: center;    
    background-color: transparent !important;;
    margin: -17px 4px;
    border-radius: 5px;   
}

.owl-nav .owl-prev span, .owl-nav .owl-next span {
    font-size: 65px;
    padding: 0 20px;
    color: var(--color-white);
    transition: .5s;
}

.owl-nav .owl-prev:hover span, 
.owl-nav .owl-next:hover span {
    color: var(--color-yellow);
}

.owl-nav .owl-prev:focus, .owl-nav .owl-next:focus {
    outline: none;
}

.owl-nav .owl-prev {
    left: -10px;
    z-index: 1200;
}

.owl-nav .owl-next {
    right: -10px;
}

.card-content .card-wrapper .owl-dots {
    display: none;
} */

@media(max-width: 900px) {
    .card-imovel {
        flex-direction: column;
    }

    .card-imovel .card-wrapper {
        margin-top: 1rem;
    }
}

@media(max-width: 767px) {
    .card-imovel p {
        display: none;
    }
}

@media(max-width: 440px) {
    .card-imovel .card-feature {
        margin-top: 1rem;
        justify-content: flex-start;
    }

    .card-imovel .card-feature li {
        width: 50%;
        line-height: 1.8;
    }

    .card-imovel .card-buttons {
        display: flex;
        justify-content: center;
        margin-top: .5rem;
    }
}