.block-CEF {
    background: var(--color-primary);    
    margin: 0;
}

.block-CEF a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .9rem;
}

.block-CEF  h2 {
    margin: 0;
    font-size: 1.2rem;
    color: var(--color-white);
    font-weight: 300;
}

.block-CEF  h2  span {
    color: var(--color-orange);    
}

.block-CEF img {
    width: 65px;
    padding: 1px;
    border-radius: 5px 5px;
    background: var(--color-white);
    margin-left: 5px;
}