.block-whatsapp  {
    position: fixed;
    bottom: 15px;
    right: 15px;
    z-index: 1000;
}

.block-whatsapp a img {
    width: 54px;
    transition: .5s;
}

.block-whatsapp a img:hover {
    width: 58px;
}