.block-location {
    padding: 1rem;
    background: var(--color-white);
    height: 400px;

    border-top: 1px solid var(--color-grey-light);

    display: flex;
    flex-direction: column;
}

.block-location h2 {
    font-size: .9rem;
}

.block-location h3 {
    font-size: .85rem;
    font-weight: 300;
}

.block-location  .block-map {
    position: relative;
    width: 100%;
    height: 100%;
    background-image: url('../../../../assets/images/map.jpeg');
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
}

.block-location .block-map .content-map {
    width: 100%;
    height: 100%;
    background: rgba(254, 254, 255, .75);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
}

.block-location .block-map .content-map span {
    padding: 1rem;
    border: 1px solid var(--color-primary);
    border-radius: .4rem;
    background: var(--color-primary);
    color: var(--color-white);
    cursor: pointer;
}

.block-location .block-map iframe {
    position: relative;
}