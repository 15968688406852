#topo-property-registration {
    background-image: url("../../../assets/images/background/property-registration.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    min-height: 600px;

    position: relative;
    display: flex;
    justify-content: space-between;
}

#topo-property-registration::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 60%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.35);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.35) 0%, transparent 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.35)), color-stop(100%, transparent));
    background: linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, transparent 100%);
}

#topo-property-registration::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-primary-dark);
    opacity: .5;
}

#topo-property-registration .container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

#topo-property-registration .content,
#topo-property-registration .content-form {
    position: relative;
    z-index: 1;
    padding: 50px 0;
}

#topo-property-registration .content {
    max-width: 50%;
}

#topo-property-registration .content strong {
    color: var(--color-yellow);
}

#topo-property-registration .content h1 {
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--color-white);
}

#topo-property-registration .content p {
    font-size: .8rem;
    font-weight: 300;
    color: var(--color-white);
}

#topo-property-registration .content ul li {
    display: flex;
    align-items: baseline;
    color: var(--color-white);
}

#topo-property-registration .content ul li i{
    margin-right: 15px;
    color: var(--color-primary-lighter);
}

#topo-property-registration .content ul li {
    margin-bottom: 15px;
    font-size: 1rem;
    font-weight: 300;
}

#topo-property-registration .content .block-alert {
    display: flex;
    align-items: center;
    margin-top: 3rem;
    padding: 1rem;

    border: 1px solid var(--color-primary-lighter);
    border-radius: .5rem;
}

#topo-property-registration .content .block-alert svg {
    font-size: 2rem;
    margin-right: 1rem;

    color: var(--color-primary-lighter);
}

#topo-property-registration .content .block-alert h2 {
    font-size: .9rem;
    font-weight: 300;
    margin: 0;
    color: var(--color-white);
}

#topo-property-registration .content-form {
    padding-left: 15px;
    max-width: 40%;
}

.block-form {
    background: var(--color-primary-light);
    border-radius: .5rem;
    padding: 30px 20px;
}

.block-form input, 
.block-form select {
    height: 48px;
    font-size: .9rem;
    font-weight: 300;
    border-radius: .2rem;
    box-shadow: none;
    margin-bottom: 1rem;
}

.block-form button {
    border: 0;
    background: var(--color-primary);
    height: 50px;
    font-weight: 300;
    transition: .5s;
}

.block-form button:hover {
    background: var(--color-primary-dark);
}

.block-form .block-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block-form .block-group .form-control:first-child {
    margin-right: 15px;
}

@media (max-width: 1200px ) {
    #topo-property-registration .content,
    #topo-property-registration .content-form {
        max-width: 100%;        
    }
    #topo-property-registration .content-form {
        padding: 0 0 50px;
    }
}

@media (max-width: 500px ) {
    .block-form .block-group {
        flex-wrap: wrap;
    }

    .block-form .block-group .form-control:first-child {
        margin-right: 0;
    }
}

@media (max-width: 350px) {
    .block-form button {
        width: 100%;
    }
    .block-form button span {
        display: none;
    }
}