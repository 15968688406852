.drawer-search{
    position: fixed;
    width: 100%;
    max-width: 320px;
    height: 100%;
    padding: 15px;
    top: 0;
    left: 0;
    z-index: 3000;
    transition: left .3s;
    background: var(--color-background);
    overflow: auto;
}

.no-drawer-search {
    left: -320px;
}

.drawer-search #form-result{
    display: block !important;
    max-width: 100% !important;
}

.drawer-search #form-result select{
    width: 100% !important;
}

.overlay {
    position: fixed;
    width: 100%;    
    height: 100%;
    background: var(--color-black);
    top: 0;
    left: 0;
    z-index: 2000;
    transition: .5s;
    opacity: 0.5;
}

.no-overlay {
    pointer-events: none;
    opacity: 0;
}

