header {
    background: var(--color-white);
    padding: 10px 0;
}

header .container {
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

header .container a {
    display: flex;
}

header img {
    max-width: 100%;
    height: 90px;
    padding: 10px;
    border-radius: 10px;
}

/* CONTACTS */
header .block-contact,
header .block-contact ul,
header .block-contact ul li,
header .block-contact ul li a,
header .block-contact ul li strong {
    display: flex;
    justify-content: flex-end;
    align-items: center;    
}

header .block-contact ul li a {
    transition: .5s;
}

header .block-contact ul li a:hover {
    color: var(--color-primary-light);
}

header .block-contact {
    border-bottom: 1px solid var(--color-background);
    padding-bottom: 10px;
}

header .block-contact ul li {
    flex-direction: row;
    margin-right: 5px;
}

header .block-contact ul li:last-child {
    margin-right: 0;
}

header .block-contact ul li span {
    display: flex;
    flex-direction: column;
    font-size: .7rem;
    margin-left: 5px;
}
header .block-contact ul li strong {
    font-size: .9rem;
    font-weight: 400;
}

header .block-contact ul li strong svg {
    margin: 0 4px;
}

/* NAVIGATION */
header nav ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

header nav ul li a {
    font-size: .9rem;
    margin-right: 15px;
    transition: .4s;
}

header nav ul li a:hover {
    color: var(--color-primary-light);
}

header nav ul li:last-child {
    margin-right: 0;
    line-height: 0;
    border-left: 1px solid var(--color-primary);
    padding-left: 10px;
    cursor: pointer;
}

header nav ul li i {
    font-size: 1rem;
    margin: 0 5px;
    color: var(--color-primary);
}

header nav ul li a span {
    color: var(--color-primary);
    background: var(--color-yellow);
    border-radius: 2px;
    min-width: 13px;
    height: 13px;
    font-size: 11px;
    text-align: center;
    margin-top: 7px;
    margin-right: 5px;
    margin-left: -10px;
    line-height: 1.4;
}

@media(max-width: 970px) {
    header .block-contact {
        display: none;
    }
}

@media(max-width: 600px) {
    header .container {
        justify-content: center;
    }
}

@media(max-width: 400px) {
    header nav ul li:nth-child(1),
    header nav ul li:nth-child(2) {
        display: none;
    }
}
