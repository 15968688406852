.drawer-menu {
    position: fixed;
    width: 100%;
    max-width: 480px;
    height: 100%;
    padding: 20px 30px 0;
    top: 0;
    right: 0;
    z-index: 5000;
    transition: right .3s;
    background: var(--color-primary);
    overflow: auto;
}

.no-drawer-menu {
    right: -480px;
}

.drawer-menu-overlay {
    position: fixed;
    width: 100%;    
    height: 100%;
    background: var(--color-black);
    top: 0;
    left: 0;
    z-index: 4999;
    transition: .5s;
    opacity: 0.5;
}

.no-overlay {
    pointer-events: none;
    opacity: 0;
}

.drawer-menu .box-buttons,
.drawer-menu .box-buttons a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.drawer-menu .box-buttons {
    flex-wrap: wrap;
}

.drawer-menu .box-buttons a {
    flex-direction: column;
    font-size: .8rem;
    color: var(--color-white);

    width: 45%;
    margin: 10px;
    height: 6rem;
    
    border: 1px solid var(--color-primary-lighter);
    transition: .5s;
}

.drawer-menu .box-buttons a:hover {
    color: var(--color-white);
    margin-top: 5px;
    margin-bottom: 15px;
    box-shadow: 0px 9px 9px -5px rgba(7, 4, 4, 0.2);
}

.drawer-menu .box-buttons a svg,
.drawer-menu .box-buttons a i {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: var(--color-primary-lighter);
}

.drawer-menu .box-services {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;

}

.drawer-menu .box-services {
    padding: 10px;
}

.drawer-menu .box-services a {
    border: 1px solid var(--color-primary-lighter);
    color: var(--color-white);
    width: 100%;
    transition: .5s;
    margin: 5px 0px;
    padding: 15px;
    font-size: .8rem;
}

.drawer-menu .box-services a:hover {
    color: var(--color-white);
    margin-top: 1px;
    margin-bottom: 9px;
    box-shadow: 0px 9px 9px -5px rgba(7, 4, 4, 0.2);
}

.drawer-menu .box-services a i {
    margin-right: 10px;
    font-size: 1rem;
    color: var(--color-primary-lighter);
}

.drawer-menu hr {
    max-width: 90%;
    margin: 1rem auto;
}

@media(max-width:460px) {
    .drawer-menu {
        padding: 20px 0px 0;
    }

    .drawer-menu .box-buttons a {
        margin: 4px;
    }
}