#featured-properties {
    padding: 15px 0 36px;
    background: var(--color-white);    
}

#featured-properties .container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.especial-card {
    position: relative;
    background: var(--color-white);    
    border: 1px solid #ddd;
    border-radius: .4rem;
    overflow: hidden;
    transition: .5s;
    min-height: 456px;
}

.especial-card:hover .image::before {
    background: var(--color-primary-dark);
}

.especial-card .image {
    position: relative;
    height: 260px;
    overflow: hidden;
    background: #dedede;
}

.especial-card .image a img {
    height: 100%;
    max-height: 260px;
    width: initial;
    margin: 0 auto;    
}

.especial-card .image::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 0;
    opacity: .6;
    transition: .5s;
    pointer-events: none;
}

.especial-card .image .content-link {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.especial-card .image .content-link span {
    border: 1px solid transparent;
    color: transparent;
    padding: 1rem;
    transition: .5s;
}

.especial-card:hover .content-link span {
    border: 1px solid var(--color-yellow);
    color: var(--color-white);
}


.especial-card .wrapper {
    padding: 1rem;
}

.especial-card .wrapper h2 {
    font-size: 1.2rem;
    color: var(--color-primary);
    margin: 0;
}

.especial-card .wrapper h2 a {
    transition: .5s;
}

.especial-card .wrapper h2 a:hover {
    color: var(--color-primary-dark);
}

.especial-card .wrapper address {
    font-size: .9rem;
    font-weight: 300;
    margin: 0;
}

.especial-card .wrapper .values {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    font-size: 1.5rem;
    color: var(--color-primary);
    padding: 1rem 0;
}

.especial-card .wrapper ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    font-size: .9rem;
    font-weight: 300;
}

#featured-properties .carousel-nav {
    font-size: 2.1rem;
    margin-top: 1rem;
}

#featured-properties .carousel-nav span {
    margin: 1.5rem;
    color: var(--color-primary);
    cursor: pointer;
}

@media (max-width: 767px) {
    .especial-card {
        min-height: 375px;
    }
}