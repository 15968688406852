#about-us {
    padding: 15px 15px 36px;
}
 
#about-us .container {
    flex-direction: column;
    background: var(--color-white);
    padding: 2rem;
    border-radius: .5rem;
    box-shadow: 0 0 7px rgb(0 0 0 / 10%);
}

#about-us .container h2  {
    font-size: 1.2rem;
    color: var(--color-primary-light);
}

#about-us .container p {
    font-size: .85rem;
    font-weight: 300;
}

#about-us .container p:last-child {
    margin: 0;
}

