#search-section {
    /* background-image: url('../../../assets/images/background/search-home.jpg'); */
    /* background-repeat: no-repeat; */
    /* background-size: cover; */

    position: relative;
    width: 100%;
    min-height: 100vh;
    
    display: flex;
    align-items: center;
}

#search-section::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 60%;   
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5);
    background: -moz-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: -webkit-linear-gradient(bottom, rgba(0,0,0,0.5) 0%, transparent 100%);
    background: -webkit-gradient(left bottom, left top, color-stop(0%, rgba(0,0,0,0.5)), color-stop(100%, transparent));
    background: linear-gradient(to top, rgba(0,0,0,0.5) 0%, transparent 100%);
}

#search-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-black);
    opacity: 0;
}

#search-section video {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100vw;
    min-height: 100vh;
    z-index: -1;
}

#search-section .container {
    z-index: 1;
    flex-direction: column;
    justify-content: center;
}

/* NAVIGATION */
#search-section h1 {
    color: var(--color-white);    
    text-align: center;
    line-height: 1.1;
    font-size: 2.4rem;
}

#search-section h1 span{
    color: var(--color-yellow);
    display: block;
    z-index: 1;
}

#search-section .MuiFormGroup-root {
    justify-content: center;
}

#search-section .MuiFormControlLabel-label{
    color: var(--color-white);
    justify-content: center;
}

#search-section .search-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#search-section .search-wrapper .field-box:first-child span {
    border-radius: 10px 0 0 10px;
    margin-right: 1px;
}

#search-section .search-wrapper .field-box:last-child span {
    border-radius: 0 10px 10px 0;
}

#search-section form {
    display: flex;
    justify-content: center;
    align-items: center;
}

#search-section form button {
    height: 2.5rem;
    margin-top: 0.8rem;
    border-radius: 0 1rem 1rem 0;
    background: var(--color-primary);
    border: 0;
    border-left: 1px solid var(--color-primary-dark);
    outline: 0;
    padding: 0 1.6rem;
    transition: .4s;
    color: var(--color-white);
}

#search-section form button:hover {
    color: var(--color-primary-lighter);
}

@media(max-width: 970px) {
    #search-section .container {
        padding-top: 170px;
    }

    #search-section {
        min-height: 630px;
    }
    
    #search-section h1 {
        padding-top: 2rem;
    }
}

@media(max-width: 530px) {
    #search-section .container {
        padding-top: 150px;
    } 
}

@media(max-width: 370px) {
    #search-section .search-wrapper {
        flex-direction: column;
    }

    #search-section .search-wrapper span {
        width: 245px;
        text-align: center;
    }

    #search-section .search-wrapper .field-box:first-child span {
        border-radius: 10px 10px 0 0;
        margin: 0 0 1px;
    }

    #search-section .search-wrapper .field-box:last-child span {
        border-radius: 0 0 10px 10px
    }
}
