#property-details-alert {
    background: var(--color-white);  
    padding: 1rem;
}

#property-details-alert .container {
    justify-content: center;
    align-items: center;
    max-width: 1210px !important;

    border: 1px solid var(--color-orange);
    border-radius: .5rem;
}

#property-details-alert svg {
    font-size: 5rem;
    margin-right: 1rem;
    color: var(--color-orange) !important;
}

#property-details-alert p {
    margin: 0;
    font-size: .75rem;
    font-weight: 300;
}

@media(max-width: 767px) {
    #property-details-alert .container {
        flex-direction: column;
        padding: 1rem;
    }

    #property-details-alert svg {
        font-size: 4rem;
        margin-bottom: 1rem;
    }
}