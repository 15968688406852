:root {  
    --color-primary: #044c82;
    --color-primary-dark: #102a6b;
    --color-primary-light: #195b8e;
    --color-primary-lighter: #2eb3e3;
    --color-secondary: #333333;
    --color-background: #f5f5f5;
    --color-text-base: #565555;

    --color-white: #fff;
    --color-grey: #86848b;
    --color-grey-light: #f9f9fc;
    --color-black: #000;
    --color-yellow: #ffe100;
    --color-orange: #ffa22f;
    --color-green: #0a6f14;
    --color-red: #e0474d;
}
  
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

@media(max-width: 1080px) {
    html {
        font-size: 93.75%;
    }
}

@media(max-width: 720px) {
    html {
        font-size: 87.5%;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    background: var(--color-background);
    color: var(--color-text-base);
}
  
body, input, button, textarea {
    font: 400 1rem Poppins, sans-serif;
    color: var(--color-text-base);
}
  
button {
    cursor: pointer;
}
  
a {
    color: inherit;
    text-decoration: none !important;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1240px !important;
    height: 100%;
    margin: 0 auto;
    padding: 0 15px;

    display: flex;
}

/* BOOTSTRAP */
.form-control {
    box-shadow: none !important;
    border: 0 !important;
}

.breadcrumb-content {
    width: 100%;
    background: #e9ecef;
}

.breadcrumb {
    padding: .75rem 0;
    margin: 0;
}

.breadcrumb .breadcrumb-item a {
    color: var(--color-grey);
    font-size: .8rem;
    font-weight: 400;
}

.breadcrumb .breadcrumb-item a:hover {
    color: var(--color-primary);
}

.breadcrumb-item{
    font-size: .8rem;
}

.breadcrumb-item.active {
    color: var(--color-grey);
    font-weight: 300;
}

/* REACT LOADING MASK */
.rc-loading::after {
    background-color: var(--color-grey-light) !important;
    border-radius: .4rem;
}

.rc-loading-icon-default {
    border-color: var(--color-background) var(--color-primary-lighter) var(--color-primary-lighter) var(--color-primary-lighter);
}

/* MATERIAL-UI*/
.MuiRadio-colorPrimary {
    color: var(--color-primary-lighter) !important;
}

/* SELECT 2 */
.select2-container--default .select2-search--dropdown .select2-search__field {
    outline: none;
    border: 1px solid var(--color-primary);
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--color-primary);
    color: var(--color-white);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    display: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 6px 14px;
}

.select2-container {
    z-index: 3000;
    width: 100% !important;
}

.select2-container--default .select2-results > .select2-results__options {
    max-height: 210px;
    font-weight: 300;
}